<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}" @click.self="closeModal">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Playbook <span>ADD NEW FAQ</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="form_grp">
                        <Form @submit="handleSubmitForm" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="answer-playbook-form">
                            <div class="group_item mt-2">
                                <label class="input_label">FAQ question:</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                    <Field autocomplete="off" type="text" name="question" v-model="form.question"  rules="required" />
                                </div>
                                <ErrorMessage name="question" class="text-danger" />
                            </div>
                            <div class="group_item mt-3">
                                <label class="input_label">FAQ answer:
                                    <div class="dropdown" @click="msgOption = !msgOption">
                                        <i class="fas fa-ellipsis-h"></i>
                                        <div class="drp_wrapper" v-if="msgOption" :class="{ active: msgOption }">
                                            <ul>
                                                <li @click="templateLibrary = !templateLibrary" :class="{ active: templateLibrary }">Import Template</li>
                                            </ul>
                                        </div>
                                    </div>
                                </label>
                                <div class="editorWpr" :class="{ 'has-error': errors.playbook_answer }">
                                    <Field autocomplete="off" name="playbook_answer" v-model="form.playbook_answer" label="email content">
                                        <redactor v-model="form.playbook_answer" :default-value="editorDefaultValue" :reset-watcher="resetToDefaultWatcher" name="playbook_answer" classlist="right_out" />
                                    </Field>
                                </div>
                                <ErrorMessage name="playbook_answer" class="text-danger" />
                                <template-library v-model="templateLibrary" :on-import="handleTemplateImport" type="email" />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const Redactor = defineAsyncComponent(() => import('@/components/Redactor'))
    const TemplateLibrary = defineAsyncComponent(() => import('@/components/TemplateLibrary'))

    export default {
        name: 'Answer Playbook',

        data () {
            return {
                form: {
                    question: '',
                    playbook_answer: '',
                    playbook_id: '',
                    question_id: '',
                    contact_id: '',
                },
                editorDefaultValue: '',
                resetToDefaultWatcher: 0,
                templateLibrary: false,
                msgOption: false,
            }
        },

        props: {
            modelValue: Boolean,
            question: Object,
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            Redactor,
            TemplateLibrary
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.form = {
                        question: vm.question.question,
                        playbook_answer: '',
                        playbook_id: vm.question.playbook_id,
                        question_id: vm.question.id,
                        contact_id: vm.question.user_id,
                    }
                }
            }
        },

        computed: mapState({
            loader: state => state.playbookModule.playbookQuestionLoader,
        }),

        methods: {
            ...mapActions({
                questionToFAQ: 'playbookModule/questionToFAQ',
                getPlaybookQuestions: 'playbookModule/getPlaybookQuestions',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmitForm () {
                const vm = this;

                const answerFAQ = vm.$refs['answer-playbook-form'];

                answerFAQ.validate().then((result) => {
                    if (result.valid) {
                        vm.handleQuestionToFAQ(answerFAQ);
                    }
                });
            },

            handleQuestionToFAQ (answerFAQ) {
                const vm = this;

                const form         = vm.form;
                form.setFieldError = answerFAQ.setFieldError;

                vm.questionToFAQ(vm.form).then((result) => {
                    const params = { page: 1, per_page: 5, playbook_id: vm.question.playbook_id };

                    vm.getPlaybookQuestions(params);
                    vm.closeModal();
                });
            },

            handleTemplateImport (template) {
                const vm = this;

                vm.form.playbook_answer   = template.email_content;
                vm.editorDefaultValue     = template.email_content;
                vm.resetToDefaultWatcher += 1;
            },
        }
    }
</script>